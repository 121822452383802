import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2024/Class6/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2024/Class6/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2024/Class6/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2024/Class6/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2024/Class6/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2024/Class6/6.jpg';
// import p7 from 'assests/Photos/FirldTrips/2024/Class6/7.jpg';
// import p8 from 'assests/Photos/FirldTrips/2024/Class6/8.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Visittobagyalakshmifarm2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class5FieldTrip/9.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },

        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },

        {
            src: p7,
            source: p7,
            rows: 2,
            cols: 1,
        },

        {
            src: p8,
            source: p8,
            rows: 2,
            cols: 1,
        },

        
        {
            src: p9,
            source: p9,
            rows: 2,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Visit to Bhagyalakshmi Farm
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 5   Date: 11 SEPTEMBER 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur organised a field trip for the students of Class 5 to Sri Bhagyalakshmi Farm on
                                    Wednesday, 11 September 2024. The purpose of this school field trip was to give our students direct, experiential learning
                                    in agriculture, livestock management and eco-friendly farming techniques. The visit to Bhagyalakshmi Farm began with an
                                    exciting tractor ride, offering a unique and picturesque view of the vast greenery around the farm. The journey through the
                                    fields was refreshing and it set the tone for the wonderful experiences that followed. The first stop was the nursery, which
                                    showcased a wide variety of plants. It was fascinating to see different species of ornamental plants, flowering plants and
                                    medicinal herbs, providing insights into the world of horticulture.
                                    <br />
                                    The students explored the beautiful rose garden, home to more than 35 varieties of roses. The vibrant colours and
                                    captivating fragrance left everyone mesmerised. They were introduced to the bio-gas plant, where we learned how organic
                                    waste is converted into sustainable energy. The farm&#39;s commitment to eco-friendly practices was impressive and
                                    educational.
                                    <br />
                                    The tour continued to the dairy farm, where we witnessed the process of milking cows and the different steps involved in
                                    dairy production was witnessed. They also visited an aquarium filled with various species of fish, adding a touch of aquatic
                                    life to the farm&#39;s diverse ecosystem. The farm also had an area with domestic animals and birds, providing a perfect balance
                                    of flora and fauna to round off the day. It was an enriching experience that brought us closer to nature and farm life.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                              "Look deep into nature, and then you will understand everything better." – Albert Einstein
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Visittobagyalakshmifarm2024;